import * as React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import "../styles/404.scss"

import Layout from "../components/layout"

const NotFoundPage = ({ data, location }) => {
    return (
        <StaticQuery
            query={graphql`
        query PageNotFoundQuery {
          wp {
            themeGeneralSettings {
              themeSettings {
              notfoundtitle
              notfoundsubtitle
              notfoundbutton {
                target
                url
                title
              }
              notfoundsecondbutton {
                target
                url
                title
              }
              notfoundimage {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        }
      `}
            render={data => {
                const content = data?.wp?.themeGeneralSettings?.themeSettings

                return (
                    <Layout location={location}>
                        {content?.notfoundimage && content?.notfoundimage?.localFile?.publicURL ? (
                            <img src={content?.notfoundimage?.localFile?.publicURL} alt={content?.notfoundimage?.altText} className="fw-image" loading="lazy" />
                        ) : null}
                        <div className="not-found-wrapper">
                            <h2 className="title">{content?.notfoundtitle}</h2>
                            <h3 className="subtitle">{content?.notfoundsubtitle}</h3>
                            <div className="buttons-block">
                                <a className="button" href={content?.notfoundbutton?.url}>{content?.notfoundbutton?.title}</a>
                                <a className="button" href={content?.notfoundsecondbutton?.url}>{content?.notfoundsecondbutton?.title}</a>
                            </div>
                        </div>
                    </Layout>
                )
            }}
        />
    )
}

export default NotFoundPage
